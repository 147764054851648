@import url('https://fonts.googleapis.com/css2?family=Mochiy+Pop+One&display=swap');
*{
    box-sizing: border-box;
    font-family: 'Mochiy Pop One', sans-serif;
}
body{
    background-color: #f7f7f7;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    margin: 0;
}
.container{
    margin: 30px auto;
    width: 350px;
}
.horizontal-menu{
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background: #333;
    text-align: center;
}
.horizontal-menu li{
    display: inline-block;
    
}
.horizontal-menu a{
    color: aliceblue;
    text-decoration: none;
    padding: 16px;
    display: block;

}