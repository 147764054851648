.report-container{
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    padding: 15px;
    margin: 15px 0 ;
}
.report-container>div{
    flex: 1;
    text-align: center;
}
.report-container>div:first-of-type{
    border-right: 1px solid #dedede;
}
.report-container{
    font-size: 20px;
    letter-spacing: 1px;
    margin: 5px 0;
}
.report.plus{
    color: #2ecc71;
}
.report.minus{
    color: red;
}
