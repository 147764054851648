.item-list{
    padding: 0;
    margin-bottom: 40px;
    list-style-type: none;

}
.item-list li{
    background-color: #ffff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
    padding: 15px;
    color: #333;
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
    position: relative;
}