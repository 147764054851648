label{
    display: inline-block;
    margin: 10px 0;
}
input{
    border: 1px solid #dedede;
    border-radius: 2px;
    display: block;
    font-size: 16px;
    padding: 10px;
    width: 100%;

}
input:focus {
    outline: none;
}
.btn{
    background-color: rgb(231, 88, 107);
    color: #fff;
    cursor: pointer;
    display: block;
    margin: 10px 0 30px;
    padding: 10px;
    font-size: 16px;
    width: 100%;
}